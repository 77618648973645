<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-row>
          <b-col sm="1">
            <b-form-group label="Tahun" label-for="select-tahun" size="sm">
              <b-form-select v-model="selectedForm" id="select-tahun" size="sm">
                <option :value="option">-Pilih-</option>
                <option value="a">2021</option>
                <option value="2">2020</option>
                <option value="3">2019</option>
                <option value="4">2018</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group label="Tanggal" label-for="select-date" size="sm">
              <a-range-picker @change="onChange" />
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group label="Resource" label-for="select-station" size="sm" inline id="select-form">
              <b-form-select v-model="selectedForm" id="select-station" size="sm">
                <option :value="option">-Pilih-</option>
                <option value="a">ME45</option>
                <option value="b">ME48</option>
                <option value="c">Metar</option>
                <option value="d">Pilot Balon</option>
                <option value="e">FKLIM</option>
                <option value="b">Iklim Mikro</option>
                <option value="b">AGM 1A</option>
                <option value="b">AGM 1B</option>
                <option value="b">Psychrometer Assman </option>
                <option value="b">Suhu Tanah</option>
                <option value="b">OP Penguapan</option>
                <option value="b">Piche Penguapan</option>
                <option value="b"> Lysimeter</option>
                <option value="b">Gunbellani</option>
                <option value="b"> Lama Penyinaran</option>
                <option value="b"> Aktinograph</option>
                <option value="b"> Thermohigograph</option>
                <option value="b"> Barograph</option>
                <option value="b"> Perawanan</option>
                <option value="b"> Form Hujan</option>
                <option value="b"> Hujan Helman</option>
                <option value="b"> Kelembaban Tanah</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group label="Parameter" label-for="select-station" size="sm">
              <b-form-select v-model="t_parameter" id="select-station" size="sm">
                <option :value="option">Pilih</option>
                <option value="tbk">TBK</option>
                <option value="tbb">TBB</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group label="Region" label-for="select-wmo" size="sm">
              <b-form-select v-model="t_wmoid" class="" id="select-wmo" size="sm">
                <option :value="option">-Pilih-</option>
                <option value="region1">Region I</option>
                <option value="region2">Region II</option>
                <option value="region3">Region III</option>
                <option value="region4">Region IV</option>
                <option value="region5">Region V</option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Stasiun" label-for="select-station" size="sm">
              <b-form-select v-model="t_station" id="select-station" size="sm">
                <option :value="option">-Pilih-</option>
                <option value="stasiun1"> Stasiun Meteorologi Maimun Saleh</option>
                <option value="2"> Stasiun Meteorologi Malikussaleh</option>
                <option value="c"> Stasiun Meteorologi Sultan Iskandar Muda</option>
                <option value="d"> Stasiun Meteorologi Cut Nyak Dhien Nagan Raya</option>
                <option value="e"> Stasiun Klimatologi Deli Serdang</option>
                <option value="f"> Stasiun Meteorologi Kualanamu</option>
                <option value="g"> Stasiun Meteorologi Maritim Belawan </option>
                <option value="h"> Stasiun Geofisika Aceh Selatan</option>
                <option value="i"> Stasiun Meteorologi Aek Godang</option>
                <option value="j"> Stasiun Meteorologi FL Tobing</option>
                <option value="k"> Stasiun Meteorologi Binaka</option>
                <option value="l"> Stasiun Meteorologi Hang Nadim</option>
                <option value="m"> Stasiun Meteorologi Raja Haji Abdullah </option>
                <option value="n"> Stasiun Klimatologi Padang Pariaman</option>
                <option value="o"> Stasiun Klimatologi Padang Pariaman</option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col sm="1 mt-2">
            <!-- <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="sm">Filter</b-button> -->
            <b-button @click="onFetch" variant="primary" class="ml-sm-6" size="sm">Submit</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <hr />

    <tabselement v-if="choosenForm == 'a'"> </tabselement>
    <!-- <magnet v-if="choosenForm == 'b'"> </magnet> -->
  </div>
</template>

<script>
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput, BTabs, BTab } from "bootstrap-vue";
//import Swal from "sweetalert2";

import Tabselement from "./Tabbyelement.vue";
//import Station from "./Componendataparameter/Bystation.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BTabs,
    BTab,
    //Swal,
    Tabselement,
    //statio: Station
  },
  data: function() {
    return {
      backgroundLoading: "#ff8000",
      selectedForm: "",
      choosenForm: "",
      t_station: "",
      t_wmoid: "",
      t_date: "",
      option: "",
      t_tahun: "",
    };
  },

  methods: {
    onFetch() {
      // Swal.fire({
      //   title: "Do you want to save the changes?",
      //   showDenyButton: true,
      //   showCancelButton: true,
      //   confirmButtonText: "Save",
      //   denyButtonText: `Don't save`,
      // }).then((result) => {
      //   /* Read more about isConfirmed, isDenied below */
      //   if (result.isConfirmed) {
      //     Swal.fire("Saved!", "", "success");
      //   } else if (result.isDenied) {
      //     Swal.fire("Changes are not saved", "", "info");
      //   }
      // });

      this.choosenForm = this.selectedForm;
      console.log("this.choosenForm", this.choosenForm);
    },
  },
  openLoadingBackground() {
    // this.$vs.loading({ background: this.backgroundLoading, color: "#ff8000" });
    // setTimeout(() => {
    //   this.$vs.loading.close();
    // }, 5000);
  },
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.ant-input {
  border: 2px solid #6e6b7b !important;
}
</style>
