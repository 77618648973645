<template>
  <div>
    <div class="row">
      <b-col sm="12">
        <!-- new table -->
        <b-card-body>
          <div class="d-flex justify-content-between  flex-wrap">
            <!-- sorting  -->
            <b-form-group label="Sort" label-size="sm" label-align-sm="left" label-cols-sm="2" label-for="sortBySelect" class="mr-1 mb-md-0">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group label="Filter" label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>
          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
        <!-- end new table -->
      </b-col>

      <!-- end table -->
    </div>
    <hr />

    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col cols="3">
            <b-form-group label-cols="4" label-cols-lg="6" label="Data Export :" label-for="select" size="sm">
              <b-form-select v-model="selectedForm" id="select-data" size="sm">
                <option :value="option">-Pilih-</option>
                <option value="a"> Tabular</option>
                <option value="b"> Chart</option>
                <option value="b"> Maps</option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group label-cols="4" label-cols-lg="6" label="Data Format :" label-for="select" size="sm">
              <b-form-select v-model="selectedForm" id="select-data" size="sm">
                <option :value="option">-Pilih-</option>
                <option value="a"> CSV</option>
                <option value="b"> PDF</option>
                <option value="b"> PNG</option>
                <option value="b"> JPG</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4"> </b-col>

          <b-col cols="2 ">
            <b-button variant="success" class="mb-1 mb-sm-0 mr-0 mr-sm-1 ml-1 float-right" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
              Download
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTable, BCol, BCard, BForm, BRow, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody } from "bootstrap-vue";

export default {
  components: {
    BTable,
    BButton,
    BFormGroup,
    BFormSelect,
    BCol,
    BCard,
    BForm,
    BRow,
    BFormInput,

    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BCardBody,
  },

  data() {
    return {
      //table new
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "full_name", label: "Element", sortable: true },
        {
          key: "id",
          label: "Range Value",
        },
        "start_date",
        "station_id",
        { key: "post", label: "Station Name", sortable: true },
      ],
      /* eslint-disable global-require */
      items: [
        {
          full_name: "ME 45",
          id: 123,
          start_date: "09/23/2016",
          station_id: "90161",
          post: "Stasiun Meteorologi Maimun Saleh",
        },
        {
          full_name: "Fklim71",
          id: 124,
          start_date: "09/23/2016",
          station_id: "90161",
          post: "Stasiun Geofisika Deli Serdang ",
        },
        {
          full_name: "Iklim Mikro",
          id: 1256,
          start_date: "09/23/2016",
          station_id: "90161",
          post: "Stasiun Meteorologi Mutiara Sis-Al Jufri ",
        },
        {
          full_name: "AGM 1A",
          id: 1209,
          start_date: "09/23/2016",
          station_id: "90161",
          post: "Stasiun Geofisika Palu  ",
        },
        {
          full_name: "Psychrometer Assman",
          id: 1209,
          start_date: "09/23/2016",
          station_id: "90161",
          post: "Stasiun Meteorologi Kasiguncu   ",
        },
        {
          full_name: "ME 45",
          id: 123,
          start_date: "09/23/2016",
          station_id: "90161",
          post: "Stasiun Meteorologi Maimun Saleh",
        },
        {
          full_name: "Fklim71",
          id: 124,
          start_date: "09/23/2016",
          station_id: "90161",
          post: "Stasiun Geofisika Deli Serdang ",
        },
        {
          full_name: "Iklim Mikro",
          id: 1256,
          start_date: "09/23/2016",
          station_id: "90161",
          post: "Stasiun Meteorologi Mutiara Sis-Al Jufri ",
        },
        {
          full_name: "AGM 1A",
          id: 1209,
          start_date: "09/23/2016",
          station_id: "90161",
          post: "Stasiun Geofisika Palu  ",
        },
        {
          full_name: "Psychrometer Assman",
          id: 1209,
          start_date: "09/23/2016",
          station_id: "90161",
          post: "Stasiun Meteorologi Kasiguncu   ",
        },
      ],
      /* eslint-disable global-require */
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      //end table new
    };
  },
  //new table
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
