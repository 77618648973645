<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card>
          <!-- <h6 class="text-dark">Data Parameter Visualization</h6> -->
          <b-tabs>
            <b-tab title="By Element">
              <element-main />
            </b-tab>
            <b-tab title="By Station">
              <station />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BRow, BCol } from "bootstrap-vue";
import Element from "./Element.vue";
import Station from "./Station.vue";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    "element-main": Element,
    station: Station,
  },
};
</script>
