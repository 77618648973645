<template>
  <div>
    <b-row>
      <b-col sm="12">
        <b-tabs align="left">
          <b-tab active title="Table"><mtable /></b-tab>
          <b-tab title="Grid" lazy><grid /></b-tab>
          <b-tab title="Chart" lazy><chart /></b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCol, BRow, BButton } from "bootstrap-vue";
import Table from "./mtable.vue";
import Chart from "./chart.vue";
import Grid from "./grid.vue";

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCard,
    BCol,
    BRow,
    mtable: Table,
    grid: Grid,
    chart: Chart,
  },
};
</script>
